<template>
  <div class="p-component-overlay" v-if="loading">
    <i class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i>
  </div>
  <div class="p-col-12">
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Datos servicio" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="solicitudServicio">Nº de solicitud</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="solicitudServicio" v-model="localService.solicitudServicio" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="tipoSolicitud">Tipo de solicitud</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="tipoSolicitud" v-model="localService.tipoSolicitud" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="tipoServicio">Tipo de servicio</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="tipoServicio" v-model="localService.tipoServicio" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="departamentoOrigen">Departamento origen</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="departamentoOrigen" v-model="localService.departamentoOrigen" type="text" readonly="true"/>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="departamentoOrigenCodigoDane" v-model="localService.departamentoOrigenCodigoDane" type="hidden"/>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="departamentoOrigenCodigo" v-model="localService.departamentoOrigenCodigo" type="hidden"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="ciudadOrigen">Ciudad origen</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="ciudadOrigen" v-model="localService.ciudadOrigen" type="text" readonly="true"/>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="ciudadOrigenCodigoDane" v-model="localService.ciudadOrigenCodigoDane" type="hidden"/>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="ciudadOrigenCodigo" v-model="localService.ciudadOrigenCodigo" type="hidden"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="departamentoDestino">Departamento destino</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="departamentoDestino" v-model="localService.departamentoDestino" type="text" readonly="true"/>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="departamentoDestinoCodigoDane" v-model="localService.departamentoDestinoCodigoDane" type="hidden"/>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="departamentoDestinoCodigo" v-model="localService.departamentoDestinoCodigo" type="hidden"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="ciudadDestino">Ciudad destino</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="ciudadDestino" v-model="localService.ciudadDestino" type="text" readonly="true"/>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="ciudadDestinoCodigoDane" v-model="localService.ciudadDestinoCodigoDane" type="hidden"/>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="ciudadDestinoCodigo" v-model="localService.ciudadDestinoCodigo" type="hidden"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="fechaCargue">Fecha de cargue</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="fechaCargue" v-model="localService.fechaCargue" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="horaCargue">Hora de cargue</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="horaCargue" v-model="localService.horaCargue" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="peso">Peso</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="peso" v-model="localService.peso" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="pesoUnidadMedida">Unidad de medida peso</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="pesoUnidadMedida" v-model="localService.pesoUnidadMedida" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="volumen">Volumen</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="volumen" v-model="localService.volumen" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="volumenUnidadMedida">Unidad de medida volumen</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="volumenUnidadMedida" v-model="localService.volumenUnidadMedida" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="vehiculo">Vehículo</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="vehiculo" v-model="localService.vehiculo" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="claseVehiculo">Clase vehículo</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="claseVehiculo" v-model="localService.claseVehiculo" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="fechaCreacion">Fecha de creación</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="fechaCreacion" v-model="localService.fechaCreacion" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="horaCreacion">Hora de creación</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="horaCreacion" v-model="localService.horaCreacion" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="estimadoHorasCargue">Horas de cargue (estimado)</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="estimadoHorasCargue" v-model="localService.estimadoHorasCargue" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="empresanombre">Empresa</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="empresanombre" v-model="localService.empresanombre" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="centroCostoNombre">Centro de costo</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="centroCostoNombre" v-model="localService.centroCostoNombre" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="tipoOperacion">Tipo de operación</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="tipoOperacion" v-model="localService.tipoOperacion" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="solicitudServicioBase">Solicitud de servicio base</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="solicitudServicioBase" v-model="localService.solicitudServicioBase" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
          </div>
          <div class="md:w-1/2 px-3">
          </div>
        </div>
        <div class="grid grid-cols-1 mb-2">
          <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="observacion">Obsevación</label>
          <Textarea class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-2 rounded" id="observacion" readonly="true" v-model="localService.observacion" rows="4"/>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Datos licitación" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="licitacionTransportadoraNombre">Nombre transportadora</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="licitacionTransportadoraNombre" v-model="localService.licitacionTransportadoraNombre" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="licitacionFleteValor">Valor flete</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="licitacionFleteValor" v-model="localService.licitacionFleteValor" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="licitacionEstado">Estado</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="licitacionEstado" v-model="localService.licitacionEstado" type="text" readonly="true"/>
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Datos cliente" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3 ">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="clienteDocumento">Nº Documento</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="clienteDocumento" v-model="localService.clienteDocumento" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="cliente">Cliente</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="cliente" v-model="localService.cliente" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="clienteDireccion">Dirección</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="clienteDireccion" v-model="localService.clienteDireccion" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="clienteTelefono">Telefono</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="clienteTelefono" v-model="localService.clienteTelefono" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
          </div>
          <div class="md:w-1/2 px-3">
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5">
      <Fieldset legend="Datos remitente" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="remitente">Remitente</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="remitente" v-model="localService.remitente" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="remitenteContacto">Contacto</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="remitenteContacto" v-model="localService.remitenteContacto" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="remitenteTelefono">Telefono</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="remitenteTelefono" v-model="localService.remitenteTelefono" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="remitenteDireccion">Dirección</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="remitenteDireccion" v-model="localService.remitenteDireccion" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
          </div>
          <div class="md:w-1/2 px-3">
          </div>
        </div>
      </Fieldset>
    </div>
    <div class="intro-y box p-5 mt-5" :key = "key"  v-for="(info_entrega,key) in localService.info_entregas">
      <Fieldset :legend="`Información entrega Nº ${info_entrega.itemEntrega}`" :toggleable="true">
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="destinatario">Destinatario</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="destinatario" v-model="info_entrega.destinatario" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="destinatarioContacto">Contacto</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="destinatarioContacto" v-model="info_entrega.destinatarioContacto" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="destinatarioDireccion">Dirección</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="destinatarioDireccion" v-model="info_entrega.destinatarioDireccion" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="destinatarioTelefono">Telefono</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="destinatarioTelefono" v-model="info_entrega.destinatarioTelefono" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="entregaEstimadaFecha">Fecha de entrega estimada</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="entregaEstimadaFecha" v-model="info_entrega.entregaEstimadaFecha" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="entregaEstimadaHora">Hora de entrega estimada</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="entregaEstimadaHora" v-model="info_entrega.entregaEstimadaHora" type="text" readonly="true"/>
          </div>
        </div>
        <div class="-mx-3 md:flex mb-2">
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="proyecto">Proyecto</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="proyecto" v-model="info_entrega.proyecto" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="sitio">Sitio</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="sitio" v-model="info_entrega.sitio" type="text" readonly="true"/>
          </div>
          <div class="md:w-1/2 px-3">
            <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="numeroPedido">Numero de pedido</label>
            <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="numeroPedido" v-model="info_entrega.numeroPedido" type="text" readonly="true"/>
          </div>
        </div>
        <div :key = "keyMercancia"  v-for="(merca,keyMercancia) in info_entrega.mercancia">
          <Fieldset :legend="`Mercancia Nº ${merca.itemMercancia}`" :toggleable="true">
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="empaqueNombre">Nombre empaque</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="empaqueNombre" v-model="merca.empaqueNombre" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="cantidad">Cantidad</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="cantidad" v-model="merca.cantidad" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorMercancia">Valor</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorMercancia" v-model="merca.valorMercancia" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorMercanciaMoneda">Moneda valor</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorMercanciaMoneda" v-model="merca.valorMercanciaMoneda" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="alto">Alto</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="alto" v-model="merca.alto" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="largo">Largo</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="largo" v-model="merca.largo" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="ancho">Ancho</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="ancho" v-model="merca.ancho" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="dimensionesUnidadMedida">Unidad de medida</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="dimensionesUnidadMedida" v-model="merca.dimensionesUnidadMedida" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="numeroUn">Numero UN</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="numeroUn" v-model="merca.numeroUn" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="naturalezaCarga">Naturaleza de la carga</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="naturalezaCarga" v-model="merca.naturalezaCarga" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="descripcionMercancia">Descripción</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="descripcionMercancia" v-model="merca.descripcionMercancia" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="pesoMercancia">Peso</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="pesoMercancia" v-model="merca.peso" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="pesoUnidadMedidaMercancia">Unidad de medida</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="pesoUnidadMedidaMercancia" v-model="merca.pesoUnidadMedida" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="volumenMercancia">Volumen</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="volumenMercancia" v-model="merca.volumen" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="volumenUnidadMedidaMercancia">Unidad de medida</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="volumenUnidadMedidaMercancia" v-model="merca.volumenUnidadMedida" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="documentoTransporte">Documento de transporte</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="documentoTransporte" v-model="merca.documentoTransporte" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="documentoTransporteTipo">Tipo documento</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="documentoTransporteTipo" v-model="merca.documentoTransporteTipo" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="documentoOrden">Nº de orden</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="documentoOrden" v-model="merca.documentoOrden" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorArancelIva">Valor arancel iva</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorArancelIva" v-model="merca.valorArancelIva" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorArancelIvaMoneda">Arancel moneda</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorArancelIvaMoneda" v-model="merca.valorArancelIvaMoneda" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="claseVehiculoAsignado">Clase vehiculo</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="claseVehiculoAsignado" v-model="merca.claseVehiculoAsignado" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="placaVehiculoAsignado">Placa vehiculo</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="placaVehiculoAsignado" v-model="merca.placaVehiculoAsignado" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="solicitudServicioBaseMercancia">Solicitud de servicio base</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="solicitudServicioBaseMercancia" v-model="merca.solicitudServicioBase" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
              </div>
            </div>
          </Fieldset>
        </div>
        <div :key = "keyContenedor"  v-for="(contenedor,keyContenedor) in info_entrega.contenedor">
          <Fieldset :legend="`Contenedor Nº ${contenedor.itemContenedor}`" :toggleable="true">
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="contenedorNumero">Número</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="contenedorNumero" v-model="contenedor.contenedorNumero" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="contenedorTipo">Tipo</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="contenedorTipo" v-model="contenedor.contenedorTipo" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="contenedorTipoAbreviatura">Tipo (Abreviatura)</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="contenedorTipoAbreviatura" v-model="contenedor.contenedorTipoAbreviatura" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="contenedorPies">Pies</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="contenedorPies" v-model="contenedor.contenedorPies" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="entregaContenedorLugar">Lugar de entrega</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="entregaContenedorLugar" v-model="contenedor.entregaContenedorLugar" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="entregaContenedorFecha">Fecha de entrega</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="entregaContenedorFecha" v-model="contenedor.entregaContenedorFecha" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="motonave">Motonave</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="motonave" v-model="contenedor.motonave" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="booking">Reserva (booking)</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="booking" v-model="contenedor.booking" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="navieraNombre">Nombre naviera</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="navieraNombre" v-model="contenedor.navieraNombre" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="lugarNombre">Nombre lugar</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="lugarNombre" v-model="contenedor.lugarNombre" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="destino">Destino</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="destino" v-model="contenedor.destino" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="estimadoArribo">Tiempo estimado arribo</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="estimadoArribo" v-model="contenedor.estimadoArribo" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="diasLibres">Días libres</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="diasLibres" v-model="contenedor.diasLibres" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="diasLibresBodegaje">Días libres bodegaje</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="diasLibresBodegaje" v-model="contenedor.diasLibresBodegaje" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="retiroPuertoFecha">Fecha retiro puerto</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="retiroPuertoFecha" v-model="contenedor.retiroPuertoFecha" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="pesoContenedor">Peso</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="pesoContenedor" v-model="contenedor.peso" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="pesoUnidadMedidaContenedor">Unidad de medida</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="pesoUnidadMedidaContenedor" v-model="contenedor.pesoUnidadMedida" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorMercanciaContenedor">Valor mercancia</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorMercanciaContenedor" v-model="contenedor.valorMercancia" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorMercanciaMonedaContenedor">Moneda valor</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorMercanciaMonedaContenedor" v-model="contenedor.valorMercanciaMoneda" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="descripcionMercanciaContenedor">Descripción mercancia</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="descripcionMercanciaContenedor" v-model="contenedor.descripcionMercancia" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="numeroUnContenedor">Numero UN</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="numeroUnContenedor" v-model="contenedor.numeroUn" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="naturalezaCargaContenedor">Naturaleza de la carga</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="naturalezaCargaContenedor" v-model="contenedor.naturalezaCarga" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="documentoTransporteContenedor">Documento de transporte</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="documentoTransporteContenedor" v-model="contenedor.documentoTransporte" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="documentoTransporteTipoContenedor">Tipo documento</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="documentoTransporteTipoContenedor" v-model="contenedor.documentoTransporteTipo" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="documentoOrdenContenedor">Nº de orden</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="documentoOrdenContenedor" v-model="contenedor.documentoOrden" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorArancelIvaContenedor">Valor arancel iva</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorArancelIvaContenedor" v-model="contenedor.valorArancelIva" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorArancelIvaMonedaContenedor">Arancel moneda</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorArancelIvaMonedaContenedor" v-model="contenedor.valorArancelIvaMoneda" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="claseVehiculoAsignadoContenedor">Clase vehiculo</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="claseVehiculoAsignadoContenedor" v-model="contenedor.claseVehiculoAsignado" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="placaVehiculoAsignadoContenedor">Placa vehiculo</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="placaVehiculoAsignadoContenedor" v-model="contenedor.placaVehiculoAsignado" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="solicitudServicioBaseContenedor">Solicitud de servicio base</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="solicitudServicioBaseContenedor" v-model="contenedor.solicitudServicioBase" type="text" readonly="true"/>
              </div>
            </div>
          </Fieldset>
        </div>
        <div :key = "keyServicioAdicional"  v-for="(servicioAdicional,keyServicioAdicional) in info_entrega.servicioAdicional">
          <Fieldset :legend="`Servicio adicional Nº ${servicioAdicional.itemServicioAdicional}`" :toggleable="true">
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="servicioAdicional">Nombre</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="servicioAdicional" v-model="servicioAdicional.servicioAdicional.nombre" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valor">Valor</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valor" v-model="servicioAdicional.valor" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="cantidadServicioAdicional">Cantidad</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="cantidadServicioAdicional" v-model="servicioAdicional.cantidad" type="text" readonly="true"/>
              </div>
            </div>
            <div class="-mx-3 md:flex mb-2">
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorTotal">Valor total</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorTotal" v-model="servicioAdicional.valorTotal" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="valorTotalMoneda">Moneda valor</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="valorTotalMoneda" v-model="servicioAdicional.valorTotalMoneda" type="text" readonly="true"/>
              </div>
              <div class="md:w-1/2 px-3">
                <label class="uppercase tracking-wide  text-xs font-bold mb-2" for="observacionEmpresa">Observación empresa</label>
                <InputText class="w-full bg-gray-200 border border-gray-200  text-xs  px-4 pr-8 mb-3 rounded" id="observacionEmpresa" v-model="servicioAdicional.observacionEmpresa" type="text" readonly="true"/>
              </div>
            </div>
          </Fieldset>
        </div>
      </Fieldset>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    service: {
      type: Object,
      default() {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    service: function(newVal) { // watch it
      this.localService = newVal;
    }
  },
  data(){
    return {
      localService: {
        "solicitudServicio": null,
        "tipoSolicitud": null,
        "cliente": null,
        "tipoServicio": null,
        "departamentoOrigen": null,
        "departamentoOrigenCodigoDane": null,
        "ciudadOrigen": null,
        "ciudadOrigenCodigoDane": null,
        "departamentoDestino": null,
        "departamentoDestinoCodigoDane": null,
        "ciudadDestino": null,
        "ciudadDestinoCodigoDane": null,
        "remitente": null,
        "remitenteContacto": null,
        "remitenteTelefono": null,
        "remitenteDireccion": null,
        "fechaCargue": null,
        "horaCargue": null,
        "peso": null,
        "pesoUnidadMedida": null,
        "volumen": null,
        "volumenUnidadMedida": null,
        "vehiculo": null,
        "claseVehiculo": null,
        "observacion": null,
        "fechaCreacion": null,
        "horaCreacion": null,
        "estimadoHorasCargue": null,
        "empresanombre": null,
        "centroCostoNombre": null,
        "licitacionTransportadoraNombre": null,
        "licitacionEstado": null,
        "licitacionFleteValor": null,
        "tipoOperacion": null,
        "solicitudServicioBase": null,
        "clienteDocumento": null,
        "clienteDireccion": null,
        "clienteTelefono": null,
        "info_entregas": []
      }
    }
  },
  created() {
    this.localService = this.service;
  }
}
</script>

<style>
.p-component-overlay{
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}
</style>